import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ShowButton,
  DateTimeInput,
  SelectInput,
  NumberInput,
  required,
  Pagination,
  DateField,
} from 'react-admin'

export const TestCreate = (props) => {
  return (
    <Create title='Create a Test' {...props}>
      <TestForm />
    </Create>
  )
}

export const TestEdit = (props) => {
  return (
    <Edit title='Edit a Test' {...props}>
      <TestForm />
    </Edit>
  )
}

export const TestList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='test_name' />
        <TextField source='test_description' />
        <TextField source='testPrice' />
        <DateField
          source='createdAt'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <TextField source='status' label='Status' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const TestForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source='test_name' fullWidth validate={[required()]} />
    <TextInput source='test_description' fullWidth />
    <NumberInput source='testPrice' fullWidth validate={[required()]} />
    <DateTimeInput label='Created' source='createdAt' fullWidth />
    <SelectInput
      source='status'
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'In Active' },
      ]}
    />
  </SimpleForm>
)
