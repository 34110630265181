import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  NumberInput,
  SelectInput,
  required,
  ImageInput,
  ImageField,
  Pagination} from 'react-admin'

// export const ProductCreate = (props) => {
//   return (
//     <Create title='Create a Product' {...props}>
//       <ProductForm />
//     </Create>
//   )
// }

export const BookProductDataEdit = (props) => {
  return (
    <Edit title='Edit a Product' {...props}>
      <BookProductDataForm />
    </Edit>
  )
}

export const BookProductDataList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='displayName' />
        <TextField source='email' />
        <TextField source='items[0].priceAfter' label='Amount' />
        <TextField source='items[0].name' label='Test Name' />
        <TextField source='items[0].quantities' label='Quantity' />
        {/* <ShowButton label='' /> */}
      </Datagrid>
    </List>
  )
}

const BookProductDataForm = (props) => (
  <SimpleForm {...props}>
    <ImageInput source='productImage' label='Product Image' accept='image/*'>
      <ImageField source='src' title='title' />
    </ImageInput>
    <TextInput source='id' disabled fullWidth />
    <TextInput source='productName' fullWidth validate={[required()]} />
    <TextInput source='productDescription' fullWidth validate={[required()]} />
    <TextInput source='productCategory' fullWidth validate={[required()]} />
    <TextInput source='brand' fullWidth validate={[required()]} />
    <TextInput source='quantity' fullWidth validate={[required()]} />
    <TextInput source='itemForum' fullWidth validate={[required()]} />
    <TextInput source='hsnCode' fullWidth validate={[required()]} />
    <NumberInput
      source='productPrice'
      fullWidth
      validate={[required()]}
      label='Unit Price'
    />
    <DateTimeInput
      label='Created'
      source='createdAt'
      fullWidth
      validate={[required()]}
    />

    <SelectInput
      validate={[required()]}
      source='status'
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'In Active' },
      ]}
    />
  </SimpleForm>
)
