import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  NumberInput,
  SelectInput,
  ShowButton,
  required,
  ArrayInput,
  SimpleFormIterator,
  Pagination,
  ReferenceInput,
} from "react-admin";

const procedureFilters = [
  <TextInput
    source="medicalProcedureName"
    label="Search for Procedures"
    alwaysOn
  />,
  // <ReferenceInput
  //   source='id'
  //   label='Medicine'
  //   reference='medicineName'
  //   allowEmpty
  // >
  //   <SelectInput optionText='name' />
  // </ReferenceInput>,
];
export const MedicalProcedureCreate = (props) => {
  return (
    <Create title="Create a Medical Procedure" {...props}>
      <MedicalProcedureForm />
    </Create>
  );
};

export const MedicalProcedureEdit = (props) => {
  return (
    <Edit title="Edit a Medical Procedure" {...props}>
      <MedicalProcedureForm />
    </Edit>
  );
};

export const MedicalProcedureList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
      filters={procedureFilters}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="medicalProcedureName" />
        <TextField source="medicalProcedurePrice" />
        <DateField
          source="createdAt"
          options={{
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }}
        />
        <TextField source="status" label="Status" />
        <ShowButton label="" />
      </Datagrid>
    </List>
  );
};

const MedicalProcedureForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="id" disabled fullWidth />
    <TextInput
      source="medicalProcedureName"
      helperText="Medical Procedure Name commonly referred in hospital"
      fullWidth
      validate={[required()]}
    />
    <ReferenceInput
      label="Select Master Procedure Type"
      source="subProcedureType"
      reference="medical_procedure_type"
      fullWidth
      filter={{ status: true }}
    >
      <SelectInput optionText="masterProcedureType" />
    </ReferenceInput>
    <TextInput source="medicalProcedureDescription" fullWidth />
    <NumberInput
      source="medicalProcedurePrice"
      fullWidth
      validate={[required()]}
    />
    <TextInput
      source="procedureVideoURL"
      type="url"
      label="Procedure Video URL"
      fullWidth
      validate={[required()]}
    />
    <SelectInput
      source="procedureType"
      choices={[
        { id: "silver", name: "Silver" },
        { id: "gold", name: "Gold" },
        { id: "platinum", name: "Platinum" },
      ]}
    />
    <ArrayInput source="faqs" label="Common FAQs">
      <SimpleFormIterator>
        <TextInput
          label="Question"
          source="question"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="Answer"
          source="answer"
          fullWidth
          validate={[required()]}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <DateTimeInput label="Created" source="createdAt" fullWidth />
    <SelectInput
      source="status"
      choices={[
        { id: "active", name: "Active" },
        { id: "inactive", name: "In Active" },
      ]}
    />
  </SimpleForm>
);
