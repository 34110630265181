import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ShowButton,
  Pagination,
  DateInput,
  required,
  ReferenceField,
  DateField,
  NumberInput,
} from 'react-admin'

export const OffersCreate = (props) => {
  return (
    <Create title='Create a Offer' {...props}>
      <OffersForm />
    </Create>
  )
}

export const OffersEdit = (props) => {
  return (
    <Edit title='Edit a Offer' {...props}>
      <OffersForm />
    </Edit>
  )
}

export const OffersList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='id' />
        <ReferenceField label='Patient' source='uid' reference='Users'>
          <TextField source='displayName' />
        </ReferenceField>
        <TextField source='offerName' />
        <TextField source='offerAmount' />
        <DateField source='offerValidity' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const OffersForm = (props) => (
  <SimpleForm {...props}>
    <ReferenceInput
      source='uid'
      reference='Users'
      fullWidth
      validate={[required()]}
    >
      <SelectInput optionText='displayName' />
    </ReferenceInput>
    <ReferenceInput
      source='uid'
      reference='Users'
      fullWidth
      validate={[required()]}
    >
      <SelectInput optionText='email' />
    </ReferenceInput>
    <TextInput source='offerName' fullWidth validate={[required()]} />
    <NumberInput source='offerAmount' fullWidth validate={[required()]} />
    <DateInput
      label='Offer Validity'
      source='offerValidity'
      fullWidth
      validate={[required()]}
    />
  </SimpleForm>
)
