import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberField,
  ReferenceInput,
  SelectInput,
  ShowButton,
  ReferenceField,
  Pagination,
} from 'react-admin'

export const EmailCreate = (props) => {
  return (
    <Create title='Create an Email Entry' {...props}>
      <EmailForm />
    </Create>
  )
}

export const EmailEdit = (props) => {
  return (
    <Edit title='Edit an Email Entry' {...props}>
      <EmailForm />
    </Edit>
  )
}

export const EmailList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <NumberField source='id' />
        <ReferenceField
          disabled
          label='Clinic Branch'
          source='clinic_id'
          reference='clinics'
          link={false}
        >
          <TextField source='clinicBranch' />
        </ReferenceField>
        <TextField source='emailRecipients' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const EmailForm = (props) => (
  <SimpleForm {...props}>
    <ReferenceInput
      label='Select Clinic'
      source='clinic_id'
      reference='clinics'
      fullWidth
      filter={{ status: true }}
    >
      <SelectInput optionText='clinicName' />
    </ReferenceInput>
    <TextInput
      multiline
      source='emailRecipients'
      label='Enter email recipients'
      fullWidth
    />
  </SimpleForm>
)
