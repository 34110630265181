import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  SelectInput,
  ShowButton,
  required,
  ArrayInput,
  SimpleFormIterator,
  Pagination,
} from 'react-admin'

export const BookCourseCreate = (props) => {
  return (
    <Create title='Create a Booking Course' {...props}>
      <BookCourseForm />
    </Create>
  )
}

export const BookCourseEdit = (props) => {
  return (
    <Edit title='Edit a Booking Course' {...props}>
      <BookCourseForm />
    </Edit>
  )
}

export const BookCourseList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='bookingCourseName' />
        <TextField source='bookingCourseDescription' />
        <DateField
          source='createdAt'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <TextField source='status' label='Status' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const BookCourseForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      source='bookingCourseName'
      helperText='Booking Course Name'
      fullWidth
      validate={[required()]}
      label='Booking Course Name'
    />
    <TextInput
      source='bookingCourseDescription'
      fullWidth
      label='Booking Course Description'
    />
    <TextInput
      source='bookingCourseURL'
      type='url'
      label='Booking Course URL'
      fullWidth
      validate={[required()]}
    />
    <DateTimeInput label='Created' source='createdAt' fullWidth />
    <SelectInput
      label='status'
      source='status'
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'In Active' },
      ]}
    />
  </SimpleForm>
)
