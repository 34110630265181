import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberField,
  ShowButton,
  Pagination,
  NumberInput,
} from 'react-admin'

export const ShippingCreate = (props) => {
  return (
    <Create title='Create an Shipping Entry' {...props}>
      <ShippingForm />
    </Create>
  )
}

export const ShippingEdit = (props) => {
  return (
    <Edit title='Edit an Shipping Entry' {...props}>
      <ShippingForm />
    </Edit>
  )
}

export const ShippingList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        {/* <NumberField source='id' /> */}
        <TextField source='shippingCostRange' />
        <NumberField source='actualShippingCost' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const ShippingForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      multiline
      source='shippingCostRange'
      label='Enter shipping cost range'
      fullWidth
    />
    <NumberInput
      multiline
      source='actualShippingCost'
      label='Actual shipping cost'
      fullWidth
    />
  </SimpleForm>
)
