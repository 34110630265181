import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  NumberInput,
  SelectInput,
  required,
  ImageInput,
  ImageField,
  Pagination,
  ReferenceField
} from 'react-admin'

// export const ProductCreate = (props) => {
//   return (
//     <Create title='Create a Product' {...props}>
//       <ProductForm />
//     </Create>
//   )
// }

const bookingFilters = [
  <TextInput
    source="centerType.clinicBranch"
    label="Search for Appointments"
    alwaysOn
  />,
  // <ReferenceInput
  //   source='id'
  //   label='Medicine'
  //   reference='medicineName'
  //   allowEmpty
  // >
  //   <SelectInput optionText='name' />
  // </ReferenceInput>,
];


// const ListActions = () => (
//   <TopToolbar>
//       <FilterButton/>
//       <CreateButton/>
//       <ExportButton/>
//       {/* Add your custom actions */}
//       <Button
//           onClick={() => { alert('Your custom action'); }}
//           label="Show calendar"
//       >
//           <IconEvent/>
//       </Button>
//   </TopToolbar>
// );
export const BookEdit = (props) => {
  return (
    <Edit title='Edit a Product' {...props}>
      <BookForm />
    </Edit>
  )
}

export const BookList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
      filters={bookingFilters}
      // actions={<ListActions/>}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='displayName' />
        <TextField source='email' />
        <TextField source='address' />
        <TextField source='category' />
        <TextField source='subCategory' label='Sub Category' />
        <TextField source='centerType.clinicBranch' label='Clinic Branch' />
        <TextField source='amount' label='Amount Paid' />
        <TextField source='mobileNumer' label='Mobile Number' />
        <ReferenceField
          disabled
          label='Doctor Name'
          source='appointmentDetail.doctor_id'
          reference='doctors'
          link={false}
        >
          <TextField source='doctor_name' />
        </ReferenceField>
        <TextField source='doctor_comments' />
        <DateField
          source='appointmentDetail.from'
          // options={{
          //   year: 'numeric',
          //   month: 'long',
          //   day: 'numeric',
          // }}
          label='Appointment Date'
          showTime
        />
        <DateField
          source='createdAt'
          options={{
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        {/* <ShowButton label='' /> */}
      </Datagrid>
    </List>
  )
}

const BookForm = (props) => (
  <SimpleForm {...props}>
    <ImageInput source='productImage' label='Product Image' accept='image/*'>
      <ImageField source='src' title='title' />
    </ImageInput>
    <TextInput source='id' disabled fullWidth />
    <TextInput source='productName' fullWidth validate={[required()]} />
    <TextInput source='productDescription' fullWidth validate={[required()]} />
    <TextInput source='productCategory' fullWidth validate={[required()]} />
    <TextInput source='brand' fullWidth validate={[required()]} />
    <TextInput source='quantity' fullWidth validate={[required()]} />
    <TextInput source='itemForum' fullWidth validate={[required()]} />
    <TextInput source='hsnCode' fullWidth validate={[required()]} />
    <NumberInput
      source='productPrice'
      fullWidth
      validate={[required()]}
      label='Unit Price'
    />
    <DateTimeInput
      label='Created'
      source='createdAt'
      fullWidth
      validate={[required()]}
    />

    <SelectInput
      validate={[required()]}
      source='status'
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'In Active' },
      ]}
    />
  </SimpleForm>
)
