import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  NumberField,
  NumberInput,
  ShowButton,
  NullableBooleanInput,
  required,
  Pagination,
} from 'react-admin'

export const ClinicCreate = (props) => {
  return (
    <Create title='Create a Clinic' {...props}>
      <ClinicForm />
    </Create>
  )
}

export const ClinicEdit = (props) => {
  return (
    <Edit title='Edit a Clinic' {...props}>
      <ClinicForm />
    </Edit>
  )
}

export const ClinicList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <NumberField source='clinicBranchCode' />
        <TextField source='clinicName' />
        <DateField
          source='createdAt'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <TextField source='clinicRating' label='Rating' />
        <TextField source='status' label='Status' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const ClinicForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source='id' disabled fullWidth />
    <TextInput source='clinicName' fullWidth />
    <TextInput multiline source='clinicBranch' fullWidth />
    <NumberInput source='clinicBranchCode' fullWidth />
    <DateTimeInput label='Created' source='createdAt' fullWidth />
    <NumberInput
      source='clinicRating'
      max='5'
      min='1'
      fullWidth
      validate={[required()]}
    />
    <DateTimeInput
      label='Clinic Open Time'
      source='openTime'
      fullWidth
      validate={[required()]}
    />
    <DateTimeInput
      label='Clinic Close time'
      source='closeTime'
      fullWidth
      validate={[required()]}
    />
    {/* <ReferenceInput
    label="Status"
    source="clinic_status_id"
    reference="status"
    fullWidth
  >
    <SelectInput optionText="text" />
  </ReferenceInput> */}
    <NumberInput source='consultationPrice' fullWidth validate={[required()]} />
    <NullableBooleanInput
      validate={[required()]}
      label='Status'
      source='status'
      falseLabel='No'
      trueLabel='Yes'
    />
  </SimpleForm>
)
