import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  SelectInput,
  ShowButton,
  required,
  ArrayInput,
  SimpleFormIterator,
  Pagination,
} from 'react-admin'

export const WellnessPackagesCreate = (props) => {
  return (
    <Create title='Create a Wellness Package' {...props}>
      <WellnessPackagesForm />
    </Create>
  )
}

export const WellnessPackagesEdit = (props) => {
  return (
    <Edit title='Edit a Wellness Package' {...props}>
      <WellnessPackagesForm />
    </Edit>
  )
}

export const WellnessPackagesList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='wellnessPackageName' />
        <TextField source='wellnessPackageDescription' />
        <DateField
          source='createdAt'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <TextField source='status' label='Status' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const WellnessPackagesForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      source='wellnessPackageName'
      helperText='Wellness Package Name'
      fullWidth
      validate={[required()]}
      label='Wellness Package Name'
    />
    <TextInput
      source='wellnessPackageDescription'
      fullWidth
      label='Wellness Package Description'
    />
    <TextInput
      source='wellnessPackageURL'
      type='url'
      label='Wellness Package URL'
      fullWidth
      validate={[required()]}
    />
    <DateTimeInput label='Created' source='createdAt' fullWidth />
    <SelectInput
      label='status'
      source='status'
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'In Active' },
      ]}
    />
  </SimpleForm>
)
