import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ShowButton,
  Pagination,
  DateInput,
  required,
} from 'react-admin';

const userFilters = [
  <TextInput source='displayName' label='Search for user name' alwaysOn />,
  <TextInput source='email' label='Search for user email' alwaysOn />,
  // <ReferenceInput
  //   source='id'
  //   label='Medicine'
  //   reference='medicineName'
  //   allowEmpty
  // >
  //   <SelectInput optionText='name' />
  // </ReferenceInput>,
];
export const UsersCreate = (props) => {
  return (
    <Create title='Create a Offer' {...props}>
      <UsersForm />
    </Create>
  );
};

export const UsersEdit = (props) => {
  return (
    <Edit title='Edit a Offer' {...props}>
      <UsersForm />
    </Edit>
  );
};

export const UsersList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
      bulkActionButtons={false}
      filters={userFilters}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='displayName' />
        <TextField source='email' />
        <TextField source='mobileNumer' />
        <TextField source='displayName' />
        <TextField source='type' label='Role' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  );
};

const UsersForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source='displayName' fullWidth />
    <TextInput source='email' fullWidth />
    <TextInput source='mobileNumer' fullWidth />
  </SimpleForm>
);
