import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  NumberField,
  ReferenceInput,
  SelectInput,
  ShowButton,
  ReferenceField,
  NullableBooleanInput,
  required,
  Pagination,
} from 'react-admin'

export const AppointmentCreate = (props) => {
  return (
    <Create title='Create a Clinic' {...props}>
      <AppointmentForm />
    </Create>
  )
}

export const AppointmentEdit = (props) => {
  return (
    <Edit title='Edit a Clinic' {...props}>
      <AppointmentForm />
    </Edit>
  )
}

export const AppointmentList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <NumberField source='id' />
        <ReferenceField
          disabled
          label='Clinic Branch Code'
          source='clinic_id'
          reference='clinics'
          link={false}
        >
          <TextField source='clinicBranchCode' />
        </ReferenceField>
        <ReferenceField
          disabled
          label='Doctor'
          source='doctor_id'
          reference='doctors'
          link={false}
        >
          <TextField source='doctor_name' />
        </ReferenceField>
        <ReferenceField
          disabled
          label='Clinic Name'
          source='clinic_id'
          reference='clinics'
          link={false}
        >
          <TextField source='clinicName' />
        </ReferenceField>
        <DateField
          label='From Time'
          source='from'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <DateField
          label='To Time'
          source='to'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const AppointmentForm = (props) => (
  <SimpleForm {...props}>
    <ReferenceInput
      label='Select Clinic'
      source='clinic_id'
      reference='clinics'
      fullWidth
      filter={{ status: true }}
    >
      <SelectInput optionText='clinicName' />
    </ReferenceInput>
    <ReferenceInput
      validate={[required()]}
      label='Select Doctor'
      source='doctor_id'
      reference='doctors'
      filter={{ confirmed: true }}
      fullWidth
    >
      <SelectInput optionText='doctor_name' />
    </ReferenceInput>
    <ReferenceInput
      validate={[required()]}
      label='Select Clinic'
      source='clinic_id'
      reference='clinics'
      filter={{ confirmed: true }}
      fullWidth
    >
      <SelectInput optionText='clinicBranchCode' />
    </ReferenceInput>
    <DateTimeInput label='From Time' source='from' fullWidth />
    <DateTimeInput label='To Time' source='to' fullWidth />
    <TextInput
      multiline
      source='videoConsultationurl'
      label='Video Consultation URL'
      fullWidth
    />
    <NullableBooleanInput
      label='Confirmed'
      source='confirmed'
      nullLabel='Either'
      falseLabel='No'
      trueLabel='Yes'
    />
  </SimpleForm>
)
