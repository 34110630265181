import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  SelectInput,
  ShowButton,
  required,
  ImageInput,
  ImageField,
  Pagination,
} from 'react-admin'

export const BannerCreate = (props) => {
  return (
    <Create title='Create a Banner' {...props}>
      <BannerForm />
    </Create>
  )
}

export const BannerEdit = (props) => {
  return (
    <Edit title='Edit a Banner' {...props}>
      <BannerForm />
    </Edit>
  )
}

export const BannerList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='id' />
        <ImageField source='bannerImage.src' label='Banner Image' />
        <TextField source='bannerName' />
        <DateField
          source='validTill'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <DateField
          source='createdAt'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <TextField source='status' label='Status' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const BannerForm = (props) => (
  <SimpleForm {...props}>
    <ImageInput source='bannerImage' label='Banner Image' accept='image/*'>
      <ImageField source='src' title='title' />
    </ImageInput>
    <TextInput source='id' disabled fullWidth />
    <TextInput source='bannerName' fullWidth validate={[required()]} />
    <DateTimeInput
      label='Valid Date'
      source='validTill'
      fullWidth
      validate={[required()]}
    />
    <DateTimeInput
      label='Created'
      source='createdAt'
      fullWidth
      validate={[required()]}
    />

    <SelectInput
      validate={[required()]}
      source='status'
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'In Active' },
      ]}
    />
  </SimpleForm>
)
