import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ShowButton,
  Pagination,
  DateInput,
  required,
  DateField,
  NumberInput,
  NumberField,
  SelectInput,
} from 'react-admin'

export const CouponCreate = (props) => {
  return (
    <Create title='Create a Coupon' {...props}>
      <CouponForm />
    </Create>
  )
}

export const CouponEdit = (props) => {
  return (
    <Edit title='Edit a Coupon' {...props}>
      <CouponForm />
    </Edit>
  )
}

export const CouponList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='couponName' />
        <TextField source='couponId' />
        <TextField source='couponMinimumAmount' />
        <NumberField source='couponAmount' />
        <TextField source='couponType' />
        <DateField source='couponValidity' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const CouponForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source='couponName' fullWidth validate={[required()]} />
    <TextInput source='couponId' fullWidth label='Coupon ID' validate={[required()]} />
    <NumberInput source='couponMinimumAmount' fullWidth validate={[required()]} />
    <NumberInput source='couponAmount' fullWidth validate={[required()]} />
    <SelectInput
      validate={[required()]}
      source='couponType'
      choices={[
        { id: '1', name: 'Percentage (%)' },
        { id: '0', name: 'Amount in figures' },
      ]}
    />
    <DateInput
      label='Coupon Validity'
      source='couponValidity'
      fullWidth
      validate={[required()]}
    />
  </SimpleForm>
)
