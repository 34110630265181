import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberField,
  ShowButton,
  required,
  Pagination,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'

export const QuestionCreate = (props) => {
  return (
    <Create title='Create a Question' {...props}>
      <QuestionForm />
    </Create>
  )
}

export const QuestionEdit = (props) => {
  return (
    <Edit title='Edit a Question' {...props}>
      <QuestionForm />
    </Edit>
  )
}

export const QuestionList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <NumberField source='id' />
        <TextField source="questions[0].question" label="Question" />
        <TextField source="diseaseName" label="Disease Name" />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const QuestionForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      label="Disease"
      source="diseaseName"
      fullWidth
      validate={[required()]}
    />
    <ArrayInput source="questions" label="Questions">
      <SimpleFormIterator>
        <TextInput
          label="Question"
          source="question"
          fullWidth
          validate={[required()]}
        />
        <ArrayInput source="options" label="Options">
          <SimpleFormIterator>
            <TextInput
              label="Option"
              source="option"
              fullWidth
              validate={[required()]}
            />
            <TextInput
              label="Question"
              source="question"
              fullWidth
            />
            <ArrayInput source="options" label="Options">
              <SimpleFormIterator>
                <TextInput
                  label="Option"
                  source="option"
                  fullWidth
                  validate={[required()]}
                />
                <TextInput
                  label="Question"
                  source="question"
                  fullWidth
                />
                <ArrayInput source="options" label="Options">
                  <SimpleFormIterator>
                    <TextInput
                      label="Option"
                      source="option"
                      fullWidth
                      validate={[required()]}
                    />
                    <TextInput
                      label="Question"
                      source="question"
                      fullWidth
                    />
                    <TextInput
                      helperText='Optional Tests can be added'
                      label="Tests"
                      source="test"
                      fullWidth
                    />
                    <TextInput
                      helperText='Optional Medications can be added'
                      label="Medications"
                      source="medication"
                      fullWidth
                    />
                    <ArrayInput source="options" label="Options">
                      <SimpleFormIterator>
                        <TextInput
                          label="Option"
                          source="option"
                          fullWidth
                          validate={[required()]}
                        />
                        <TextInput
                          label="Question"
                          source="question"
                          fullWidth
                        />
                        <TextInput
                          helperText='Optional Tests can be added'
                          label="Tests"
                          source="test"
                          fullWidth
                        />
                        <TextInput
                          helperText='Optional Medications can be added'
                          label="Medications"
                          source="medication"
                          fullWidth
                        />
                        <ArrayInput source="options" label="Options">
                          <SimpleFormIterator>
                            <TextInput
                              label="Option"
                              source="option"
                              fullWidth
                              validate={[required()]}
                            />
                            <TextInput
                              label="Question"
                              source="question"
                              fullWidth
                            />
                            <TextInput
                              helperText='Optional Tests can be added'
                              label="Tests"
                              source="test"
                              fullWidth
                            />
                            <TextInput
                              helperText='Optional Medications can be added'
                              label="Medications"
                              source="medication"
                              fullWidth
                            />
                            <ArrayInput source="options" label="Options">
                              <SimpleFormIterator>
                                <TextInput
                                  label="Option"
                                  source="option"
                                  fullWidth
                                  validate={[required()]}
                                />
                                <TextInput
                                  label="Question"
                                  source="question"
                                  fullWidth
                                />
                                <TextInput
                                  helperText='Optional Tests can be added'
                                  label="Tests"
                                  source="test"
                                  fullWidth
                                />
                                <TextInput
                                  helperText='Optional Medications can be added'
                                  label="Medications"
                                  source="medication"
                                  fullWidth
                                />
                                <ArrayInput source="options" label="Options">
                                  <SimpleFormIterator>
                                    <TextInput
                                      label="Option"
                                      source="option"
                                      fullWidth
                                      validate={[required()]}
                                    />
                                    <TextInput
                                      label="Question"
                                      source="question"
                                      fullWidth
                                    />
                                    <TextInput
                                      helperText='Optional Tests can be added'
                                      label="Tests"
                                      source="test"
                                      fullWidth
                                    />
                                    <TextInput
                                      helperText='Optional Medications can be added'
                                      label="Medications"
                                      source="medication"
                                      fullWidth
                                    />
                                  </SimpleFormIterator>
                                </ArrayInput>
                              </SimpleFormIterator>
                            </ArrayInput>
                          </SimpleFormIterator>
                        </ArrayInput>
                      </SimpleFormIterator>
                    </ArrayInput>
                  </SimpleFormIterator>
                </ArrayInput>
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
    {/* <ReferenceInput
      label='Select Clinic'
      source='clinic_id'
      reference='clinics'
      fullWidth
      filter={{ status: true }}
    >
      <SelectInput optionText='clinicName' />
    </ReferenceInput>
    <ReferenceInput
      validate={[required()]}
      label='Select Doctor'
      source='doctor_id'
      reference='doctors'
      filter={{ confirmed: true }}
      fullWidth
    >
      <SelectInput optionText='doctor_name' />
    </ReferenceInput>
    <ReferenceInput
      validate={[required()]}
      label='Select Clinic'
      source='clinic_id'
      reference='clinics'
      filter={{ confirmed: true }}
      fullWidth
    >
      <SelectInput optionText='clinicBranchCode' />
    </ReferenceInput>
    <DateTimeInput label='From Time' source='from' fullWidth />
    <DateTimeInput label='To Time' source='to' fullWidth />
    <TextInput
      multiline
      source='videoConsultationurl'
      label='Video Consultation URL'
      fullWidth
    />
    <NullableBooleanInput
      label='Confirmed'
      source='confirmed'
      nullLabel='Either'
      falseLabel='No'
      trueLabel='Yes'
    /> */}
  </SimpleForm>
)
