import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ShowButton,
  NullableBooleanInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  required,
  Pagination,
} from 'react-admin'

export const DoctorCreate = (props) => {
  return (
    <Create title='Create a Doctor' {...props}>
      <DoctorForm />
    </Create>
  )
}

export const DoctorEdit = (props) => {
  return (
    <Edit title='Edit a Doctor' {...props}>
      <DoctorForm />
    </Edit>
  )
}

export const DoctorList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='doctor_name' />
        <TextField source='doctor_qualification' />
        <TextField source='doctor_specialization' />
        <TextField source='doctor_experience' />
        <TextField source='confirmed' label='Active' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const DoctorForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source='doctor_name' fullWidth />
    <TextInput source='doctor_qualification' fullWidth />
    <TextInput source='doctor_specialization' fullWidth />
    <TextInput source='doctor_experience' fullWidth />
    <NumberInput source='consultationPrice' fullWidth validate={[required()]} />
    <ReferenceInput
      label='Select Clinic'
      source='clinic_id'
      reference='clinics'
      fullWidth
      filter={{ status: true }}
    >
      <SelectInput optionText='clinicName' />
    </ReferenceInput>
    <NullableBooleanInput
      label='Confirmed'
      source='confirmed'
      nullLabel='Either'
      falseLabel='No'
      trueLabel='Yes'
    />
  </SimpleForm>
)
