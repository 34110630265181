import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  NumberInput,
  SelectInput,
  ShowButton,
  required,
  ImageInput,
  ImageField,
  Pagination,
} from 'react-admin'

export const ProductCreate = (props) => {
  return (
    <Create title='Create a Product' {...props}>
      <ProductForm />
    </Create>
  )
}

export const ProductEdit = (props) => {
  return (
    <Edit title='Edit a Product' {...props}>
      <ProductForm />
    </Edit>
  )
}

export const ProductList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='id' />
        <ImageField source='productImage.src' label='Product Image' />
        <TextField source='productName' />
        <TextField source='hsnCode' />
        <TextField source='productPrice' label='Unit Price' />
        <DateField
          source='createdAt'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <TextField source='status' label='Status' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const ProductForm = (props) => (
  <SimpleForm {...props}>
    <ImageInput source='productImage' label='Product Image' accept='image/*'>
      <ImageField source='src' title='title' />
    </ImageInput>
    <TextInput source='id' disabled fullWidth />
    <TextInput source='productName' fullWidth validate={[required()]} />
    <TextInput source='productDescription' fullWidth validate={[required()]} />
    <TextInput source='productCategory' fullWidth validate={[required()]} />
    <TextInput source='brand' fullWidth validate={[required()]} />
    <TextInput source='quantity' fullWidth validate={[required()]} />
    <TextInput source='itemForum' fullWidth validate={[required()]} />
    <TextInput source='hsnCode' fullWidth validate={[required()]} />
    <NumberInput
      source='productPrice'
      fullWidth
      validate={[required()]}
      label='Unit Price'
    />
    <NumberInput
      source='shippingPrice'
      fullWidth
      validate={[required()]}
      label='Shipping Price'
    />
    <TextInput
      source="productVideoURL"
      type="url"
      label="Product Video URL"
      fullWidth
      validate={[required()]}
    />
    <DateTimeInput
      label='Created'
      source='createdAt'
      fullWidth
      validate={[required()]}
    />

    <SelectInput
      validate={[required()]}
      source='status'
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'In Active' },
      ]}
    />
  </SimpleForm>
)
