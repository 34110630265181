import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  SelectInput,
  ShowButton,
  required,
  ArrayInput,
  SimpleFormIterator,
  Pagination,
} from 'react-admin'

export const NailCreate = (props) => {
  return (
    <Create title='Create a Nail Data' {...props}>
      <NailForm />
    </Create>
  )
}

export const NailEdit = (props) => {
  return (
    <Edit title='Edit a Nail Data' {...props}>
      <NailForm />
    </Edit>
  )
}

export const NailList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='nailSource' />
        <DateField
          source='createdAt'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <TextField source='status' label='Status' />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const NailForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source='id' disabled fullWidth />
    <TextInput
      source='nailSource'
      defaultValue='Nail'
      disabled
      fullWidth
      validate={[required()]}
    />
    <SelectInput
      validate={[required()]}
      source='status'
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'In Active' },
      ]}
    />
    <DateTimeInput
      label='Created'
      source='createdAt'
      fullWidth
      validate={[required()]}
    />
    <ArrayInput source='hairOptionQuestions' label='Yes or No questions'>
      <SimpleFormIterator>
        <TextInput
          label='Yes or No questions'
          source='optionQuestions'
          fullWidth
          validate={[required()]}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput source='hairDescQuestions' label='Description questions'>
      <SimpleFormIterator>
        <TextInput
          label='Description questions'
          source='descriptionQuestions'
          fullWidth
          validate={[required()]}
        />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
)
