import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberField,
  DateField,
  NumberInput,
  SelectInput,
  ShowButton,
  required,
  DateInput,
  Pagination,
  ReferenceInput,
} from 'react-admin'

const medicineFilters = [
  <TextInput source='medicineName' label='Search for Medicines' alwaysOn />,
  // <ReferenceInput
  //   source='id'
  //   label='Medicine'
  //   reference='medicineName'
  //   allowEmpty
  // >
  //   <SelectInput optionText='name' />
  // </ReferenceInput>,
]

export const MedicineCreate = (props) => {
  return (
    <Create title='Create a Medicine' {...props}>
      <MedicineForm />
    </Create>
  )
}

export const MedicineEdit = (props) => {
  return (
    <Edit title='Edit a Medicine' {...props}>
      <MedicineForm />
    </Edit>
  )
}

export const MedicineList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
      filters={medicineFilters}
    >
      <Datagrid>
        <TextField source='id' />
        <TextField source='medicineName' />
        <DateField
          source='medicineExpiry'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
        />
        <NumberField source='medicinePrice' label='MRP price' />
        <NumberField source='sellingPrice' label='Selling price' />
        <TextField
          source='stockAvailability'
          label='Stock Availability (1:yes, 0: no)'
        />
        <ShowButton label='' />
      </Datagrid>
    </List>
  )
}

const MedicineForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source='id' disabled fullWidth />
    <TextInput source='medicineName' fullWidth validate={[required()]} />
    <DateInput
      label='Medicine Expiry'
      source='medicineExpiry'
      fullWidth
      validate={[required()]}
      options={{
        mode: 'landscape',
        minDate: new Date(),
        hintText: 'Medicine Expiry Date',
      }}
    />
    <NumberInput
      source='medicinePrice'
      fullWidth
      validate={[required()]}
      label='MRP Price'
    />
    <NumberInput
      source='sellingPrice'
      fullWidth
      validate={[required()]}
      label='Selling Price'
    />
    <NumberInput
      source='shippingPrice'
      fullWidth
      validate={[required()]}
      label='Shipping Price'
    />
    <SelectInput
      validate={[required()]}
      source='stockAvailability'
      choices={[
        { id: '1', name: 'Available' },
        { id: '0', name: 'Out of Stock' },
      ]}
    />
  </SimpleForm>
)
