import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  DateField,
  NumberField,
  ReferenceInput,
  SelectInput,
  ShowButton,
  ReferenceField,
  NullableBooleanInput,
  required,
  Pagination,
} from "react-admin";

export const MedicalProcedureTypeCreate = (props) => {
  return (
    <Create title="Create a Medical Procedure Type" {...props}>
      <MedicalProcedureTypeForm />
    </Create>
  );
};

export const MedicalProcedureTypeEdit = (props) => {
  return (
    <Edit title="Edit a Medical Procedure Type" {...props}>
      <MedicalProcedureTypeForm />
    </Edit>
  );
};

export const MedicalProcedureTypeList = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} perPage={30} />}
    >
      <Datagrid>
        <TextField source="masterProcedureType" />
        <TextField source="status" label="Status" />
        <ShowButton label="" />
      </Datagrid>
    </List>
  );
};

const MedicalProcedureTypeForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      source="masterProcedureType"
      label="Enter the procedure type"
      fullWidth
      required
    />
    <NullableBooleanInput
      validate={[required()]}
      label="Active"
      source="status"
      nullLabel="Either"
      falseLabel="No"
      trueLabel="Yes"
    />
  </SimpleForm>
);
