//custom login page
import LoginPage from "./Modules/Login/LoginPage";
import React, {useEffect, useState} from "react";

import {    LocalHospitalIcon,
  GridViewIcon,
  MedicationIcon,
  LocalOfferIcon,
  GroupIcon,
  AppRegistrationIcon,
  ProductionQuantityLimitsIcon,
  VaccinesIcon,
  TouchAppIcon, 
  FaceRetouchingNaturalIcon,
  PanToolIcon,
  SpaIcon,
  BookmarkAddIcon,
  ViewCarouselIcon,
  QuizIcon,
  HttpsIcon,
  MergeTypeIcon,
  HelpOutlineIcon,
  CalendarMonthIcon,
  DateRangeIcon,
  EventAvailableIcon,
  ForwardToInboxIcon,
  CardTravelIcon,
  LocalActivityIcon
} from './icons'

import { Admin, Resource, Layout, AppBar } from "react-admin";
import {
  ClinicCreate,
  ClinicEdit,
  ClinicList,
} from "./resources/ClinicsResource";
import {
  StatusCreate,
  StatusEdit,
  StatusList,
} from "./resources/StatusResource";
import {
  AppointmentCreate,
  AppointmentEdit,
  AppointmentList,
} from "./resources/AppointmentsResource";
import {
  DoctorCreate,
  DoctorEdit,
  DoctorList,
} from "./resources/DoctorsResource";
import {
  OffersCreate,
  OffersEdit,
  OffersList,
} from "./resources/OffersResource";
import { UsersList } from "./resources/UsersResource";
import {
  MedicalProcedureCreate,
  MedicalProcedureEdit,
  MedicalProcedureList,
} from "./resources/MedicalProcedureResource";
import {
  ProductCreate,
  ProductEdit,
  ProductList,
} from "./resources/ProductResource";
import {
  MedicineCreate,
  MedicineEdit,
  MedicineList,
} from "./resources/MedicineResource";
import {
  BannerCreate,
  BannerEdit,
  BannerList,
} from "./resources/BannerResource";
import { SkinCreate, SkinEdit, SkinList } from "./resources/SkinResource";
import { HairCreate, HairEdit, HairList } from "./resources/HairResource";
import { NailCreate, NailEdit, NailList } from "./resources/NailResource";
import {
  WellnessPackagesCreate,
  WellnessPackagesEdit,
  WellnessPackagesList,
} from "./resources/WellnessPackagesResource";
import {
  BookCourseCreate,
  BookCourseEdit,
  BookCourseList,
} from "./resources/BookCourseResource";
import {
  MedicalProcedureTypeCreate,
  MedicalProcedureTypeEdit,
  MedicalProcedureTypeList,
} from "./resources/MedicalProcedureTypeResource";
import {
  QuestionCreate,
  QuestionEdit,
  QuestionList,
} from "./resources/QuestionnaireResource";
import {
  BookList
} from "./resources/BookingProductResource";

import {
  BookTestList
} from "./resources/BookingTestResource";

import {
  BookProductDataList
} from "./resources/BookingProductsDataResource";


import { TestCreate, TestEdit, TestList } from "./resources/TestsResource";
import { EmailCreate, EmailEdit, EmailList } from "./resources/EmailResource";
import { ShippingCreate, ShippingEdit, ShippingList} from "./resources/ShippingResource";
import { CouponCreate, CouponEdit, CouponList} from "./resources/CouponResource";
import { createMuiTheme } from "@material-ui/core";
// import { HelpOutline } from '@material-ui/icons'
import { createBrowserHistory as createHistory } from "history";

// Firebase setup

import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from "react-admin-firebase";

import authProvider from "./authProvider";
import firebase from 'firebase'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBM28M-CYSthN2NML9Z5_teegbyzW2bsJA",
  authDomain: "dralkas-app.firebaseapp.com",
  projectId: "dralkas-app",
  storageBucket: "dralkas-app.appspot.com",
  messagingSenderId: "602438772839",
  appId: "1:602438772839:web:5115995c0334dd1808ccc2",
  measurementId: "G-7WS2WDBG0D",
};

const options = {};

const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProviderOrig = FirebaseAuthProvider(firebaseConfig);
// console.log('authProvider', authProvider)

console.log(authProvider);

//firebase setup end

const history = createHistory();

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: { main: "#0D6690", contrastText: "#fff" },
    secondary: { main: "#0D6690", contrastText: "#fff" },
  },
});
function App() {
  const [isRestricted, setIsRestricted] = useState(false)
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email && (user.email === 'info.dralkaskincare@gmail.com' || user.email === 'reception.ptk.dralkaskincare@gmail.com' )) {
        setIsRestricted(true)
      }
    })
  }, [])

  if(!isRestricted) {
  return (
    <Admin
      loginPage={LoginPage}
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProviderOrig}
      layout={CustomLayout}
      title="Dr. Alkas Admin Panel"
      history={history}
    >
      <Resource
        name="clinics"
        options={{ label: "Clinics" }}
        list={ClinicList}
        create={ClinicCreate}
        edit={ClinicEdit}
        icon={LocalHospitalIcon}
      />
      <Resource
        name="appointments"
        options={{ label: "Appointments" }}
        list={AppointmentList}
        create={AppointmentCreate}
        edit={AppointmentEdit}
        icon={GridViewIcon}
        />
      <Resource
        name="doctors"
        options={{ label: "Doctors" }}
        list={DoctorList}
        create={DoctorCreate}
        edit={DoctorEdit}
        icon={MedicationIcon}
        />
      <Resource
        name="offers"
        options={{ label: "Offers" }}
        list={OffersList}
        create={OffersCreate}
        edit={OffersEdit}
        icon={LocalOfferIcon}
        />
      <Resource
        name="Users"
        options={{ label: "Users" }}
        list={UsersList}
        icon={GroupIcon}
        />
      <Resource
        name="medicalProcedures"
        options={{ label: "Medical Procedures" }}
        list={MedicalProcedureList}
        create={MedicalProcedureCreate}
        edit={MedicalProcedureEdit}
        icon={AppRegistrationIcon}
        />
      <Resource
        name="products"
        options={{ label: "Products" }}
        list={ProductList}
        create={ProductCreate}
        edit={ProductEdit}
        icon={ProductionQuantityLimitsIcon}
        />
      <Resource
        name="medicine"
        options={{ label: "Medicine" }}
        list={MedicineList}
        create={MedicineCreate}
        edit={MedicineEdit}
        icon={VaccinesIcon}
        />
      <Resource
        name="skinData"
        options={{ label: "Skin Data" }}
        list={SkinList}
        create={SkinCreate}
        edit={SkinEdit}
        icon={TouchAppIcon}
      />
      <Resource
        name="hairData"
        options={{ label: "Hair Data" }}
        list={HairList}
        create={HairCreate}
        edit={HairEdit}
        icon={FaceRetouchingNaturalIcon}
      />
      <Resource
        name="nailData"
        options={{ label: "Nail Data" }}
        list={NailList}
        create={NailCreate}
        edit={NailEdit}
        icon={PanToolIcon}
      />
      <Resource
        name="wellnessPackages"
        options={{ label: "Wellness Packages" }}
        list={WellnessPackagesList}
        create={WellnessPackagesCreate}
        edit={WellnessPackagesEdit}
        icon={SpaIcon}
        />
      <Resource
        name="bookCourse"
        options={{ label: "Book Course" }}
        list={BookCourseList}
        create={BookCourseCreate}
        edit={BookCourseEdit}
        icon={BookmarkAddIcon}
      />
      <Resource
        name="banners"
        options={{ label: "Banner" }}
        list={BannerList}
        create={BannerCreate}
        edit={BannerEdit}
        icon={ViewCarouselIcon}
        />
      <Resource
        name="tests"
        options={{ label: "Test Data" }}
        list={TestList}
        create={TestCreate}
        edit={TestEdit}
        icon={QuizIcon}
        />
      <Resource
        name="status"
        options={{ label: "Master Status" }}
        list={StatusList}
        create={StatusCreate}
        edit={StatusEdit}
        icon={HttpsIcon}
      />
      <Resource
        name="medical_procedure_type"
        options={{ label: "Medical Procedure Master Type" }}
        list={MedicalProcedureTypeList}
        create={MedicalProcedureTypeCreate}
        edit={MedicalProcedureTypeEdit}
        icon={MergeTypeIcon}
        />
      <Resource
        name="questionnaire"
        options={{ label: "Questionniare" }}
        list={QuestionList}
        create={QuestionCreate}
        edit={QuestionEdit}
        icon={HelpOutlineIcon}
        />
      <Resource
        name="bookings"
        options={{ label: "Appointments Data" }}
        list={BookList}
        icon={CalendarMonthIcon}
        />
      <Resource
        name="bookTest"
        options={{ label: "Tests Booking Data" }}
        list={BookTestList}
        icon={DateRangeIcon}
        />
      <Resource
        name="bookProduct"
        options={{ label: "Products Booking Data" }}
        list={BookProductDataList}
        icon={EventAvailableIcon}
        />
      <Resource
        name="email"
        options={{ label: "Email Details" }}
        list={EmailList}
        create={EmailCreate}
        edit={EmailEdit}
        icon={ForwardToInboxIcon}
        />
      <Resource
        name="shippingDetails"
        options={{ label: "Shipping Details" }}
        list={ShippingList}
        create={ShippingCreate}
        edit={ShippingEdit}
        icon={CardTravelIcon}
      />
      <Resource
        name="couponDetails"
        options={{ label: "Coupon Details" }}
        list={CouponList}
        create={CouponCreate}
        edit={CouponEdit}
        icon={LocalActivityIcon}
      />
    </Admin>
  );
  }else {
    return (
      <Admin
      loginPage={LoginPage}
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProviderOrig}
      layout={CustomLayout}
      title="Dr. Alkas Admin Panel"
      history={history}
    >
      <Resource
        name="bookings"
        options={{ label: "Appointments Data" }}
        list={BookList}
      />
      <Resource
        name="doctors"
      />
      <Resource
        name="bookTest"
        options={{ label: "Tests Booking Data" }}
        list={BookTestList}
        icon={CalendarMonthIcon}
      />
      <Resource
        name="bookProduct"
        options={{ label: "Products Booking Data" }}
        list={BookProductDataList}
        icon={EventAvailableIcon}
      />
      </Admin>
    )
  }
}
const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;
const CustomAppBar = ({ ...props }) => (
  <AppBar {...props}>
    {/* <Typography style={{ flex: 1 }}>{props.title}</Typography>
    <IconButton onClick={() => history.push('/support')}>
      <HelpOutline style={{ fill: '#fff' }} />
    </IconButton> */}
  </AppBar>
);

export default App;

// write sum_pairs function. It has 2 arguments, first is an array of numbers, and second is number.
// Find the adjacent numbers, of which the total is the second argument.
// If the number already belongs to pair, it cannot be part of another pair
// Eg: sum_pairs([11, 3, 7, 3, 5, 5, 3, 7], 10);  //ans [[3, 7], [5, 5], [3, 7]]
// Important [7,3]  is not part of answer, because 7 is already part of first [3,7] pair
